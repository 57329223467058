import React, { Component, useContext, useEffect, useMemo, useRef, useState } from 'react';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';

import * as WEBSITES from '../constants/website';
import CustomFilter from './CustomFilter';

const FilterContent = ({
  filterList,
  applyFilters: applyFiltersCopy,
  cancelFilters,
  clearFilters = () => { },
  deviceType,
  hideFilters: intialHideFilters,
  clearId = true,
  scrollToFilter,
  isFilterPopupVisible = false,
  isDealsPage = false
}) => {
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [filterListState, setfilterListState] = useState(JSON.parse(JSON.stringify(filterList || [])) || []);
  const [clearIdState, setclearIdState] = useState(clearId);
  const hideFilters = intialHideFilters;
  const router = useRouter();

  if ([WEBSITES.PERFECTBLENDS, WEBSITES.KIKOKO, WEBSITES.SULODISTRO].includes(process.env.NEXT_PUBLIC_APP)) {
    hideFilters.push('Price');
  }

  if ([WEBSITES.PERFECTBLENDS, WEBSITES.BUYPROOFNOW, WEBSITES.KIKOKO].includes(process.env.NEXT_PUBLIC_APP)) {
    hideFilters.push('Strain Types');
  }

  if ([WEBSITES.CLAYBOURNE].includes(process.env.NEXT_PUBLIC_APP)) {
    hideFilters.push('Brands');
  }

  useEffect(() => {
    const list = JSON.parse(JSON.stringify(filterList || []));
    setfilterListState(list)

    if (isNull(selectedFilter)) {
      handleFilterChange(list[0])
    } else {
      let filter = list?.find(item => item.title === selectedFilter.title)
      if (filter) {
        handleFilterChange(filter)
      }
    }
  }, [filterList]);

  useEffect(() => {
    if (!isFilterPopupVisible && selectedFilter) {
      setSelectedFilter(prevFilter => {
        const { name } = prevFilter;
        let list = filterListState.map(filter => {
          let tempFilter = filter;
          const { values = [] } = tempFilter;
          if (name !== tempFilter.name || name === "product_price") return tempFilter;
          // Ordering based on the array has checked items or not
          const hasCheckedItems = values.some(item => item.checked)
          if (hasCheckedItems) {
            const checked = values.filter(item => item.checked)
            const unchecked = values.filter(item => !item.checked)
            tempFilter.values = [...checked, ...unchecked]
          } else {
            let orderedList = [...values]
            if (name === "brands") {
              orderedList.sort((a, b) => a.value.localeCompare(b.value))
            } else if (name === "deviceTypes") {
              orderedList.sort((a, b) => parseInt(a.id) - parseInt(b.id))
            } else {
              orderedList.sort((a, b) => parseInt(a.id.split("-")[1]) - parseInt(b.id.split("-")[1]))
            }
            tempFilter.values = [...orderedList]
          }
          return tempFilter;
        })
        setfilterListState(list)
        return filter;
      })
    }
  }, [isFilterPopupVisible])

  const debouncedOnchange = useRef(debounce((filters) => {
    applyFiltersCopy(filters)
  }, 1000, { leading: false, trailing: true }))

  function applyFilters(filters) {
    debouncedOnchange.current(filters)
  }

  const onChange = (event, type) => {
    const { name, value, checked } = event.target;

    const filters = filterListState.map(item => {
      let filter = { ...item };
      if (filter.name === name) {
        filter.values = filter.values.map((subItem) => {
          let optionValue = { ...subItem };

          if (type === "checkbox") {
            optionValue.checked = optionValue.value === value ? checked : optionValue.checked;
          } else {
            optionValue.intended = true;
            optionValue.checked = optionValue.value === value ? checked : !checked
          }
          return optionValue;
        });
      }
      return filter;
    });

    setfilterListState(filters);
    let updatedFilter = filters.find(item => item.title === selectedFilter.title);
    handleFilterChange(updatedFilter)
    applyFilters(filters);
    scrollToFilter()
  };

  const clearFiltersLocal = () => {
    const filters = JSON.parse(JSON.stringify(filterListState));

    const clearedFilters =
      filters &&
      filters.map(filter => {
        if (filter.name == 'priceRange') {
          filter.isSet = false;
        }
        filter.values = filter.values.map(el => {
          if (el.name == 'min_price') {
            el.value = filter.minPrice;
            return el;
          }
          if (el.name == 'max_price') {
            el.value = filter.maxPrice;
            return el;
          }

          el.checked = false;
          return el;
        });
        return filter;
      });
    if (clearedFilters && clearedFilters.length) clearedFilters[0].values[0].checked = true;
    setfilterListState(clearedFilters);
    clearFilters();
  };

  useEffect(() => {
    if (clearId != clearIdState) {
      clearFilters();
      setclearIdState(clearId);
    }
  }, [clearId]);


  const handleFilterChange = (filter, reorderList = false) => {
    setSelectedFilter(prevFilter => {
      if (reorderList) {
        const { name } = prevFilter;
        let list = filterListState.map(filter => {
          let tempFilter = filter;
          const { values = [] } = tempFilter;
          if (name !== tempFilter.name || name === "product_price") return tempFilter;
          // Ordering based on the array has checked items or not
          const hasCheckedItems = values.some(item => item.checked)
          if (hasCheckedItems) {
            const checked = values.filter(item => item.checked)
            const unchecked = values.filter(item => !item.checked)
            tempFilter.values = [...checked, ...unchecked]
          } else {
            let orderedList = [...values]
            if (name === "brands") {
              orderedList.sort((a, b) => a.value.localeCompare(b.value))
            } else if (name === "deviceTypes") {
              orderedList.sort((a, b) => parseInt(a.id) - parseInt(b.id))
            } else {
              orderedList.sort((a, b) => parseInt(a.id.split("-")[1]) - parseInt(b.id.split("-")[1]))
            }
            tempFilter.values = [...orderedList]
          }
          return tempFilter;
        })
        setfilterListState(list)
      }
      return filter;
    })
  }

  return (
    <div className="sidebarContent new-filters">
      <div className="sidebarBanner d-flex align-items-center justify-content-between">
        <h6 className="title m-0 bold text-primary relative">
          {process.env.NEXT_PUBLIC_APP == WEBSITES.GRASSDOOR ? 'FILTER AND SORT' : 'Filters'}
        </h6>
        <button className="btn btn-link clear-btn" onClick={clearFiltersLocal}>
          Clear
        </button>
      </div>
      <div className="filter-sec-parent">

        <div className='filters-left' >
          {filterListState.map((filter) => {
            const { title = "", name = "", values = [] } = filter;

            const selectedFiltersCount = values.reduce((total, option) => {
              if (option.checked) total++;
              return total;
            }, 0)

            if (name === "priceRange") return;
            if (title.toLowerCase() === "brands" && (router.pathname.includes("/brand") || router.pathname.includes("/brands"))) return;
            if (!isDealsPage && (title.toLowerCase() === "categories" && (router.pathname.includes("/category") || router.pathname.includes("/categories")))) return;
            if (!values.length) return null;

            return (
              <div className='filters-b'>
                <div
                  onClick={() => handleFilterChange(filter, true)}
                  className={`filters-prop ${selectedFilter?.title === title ? "filter-selected" : "not-selected-filter"}`}>
                  {title}
                  {name !== "product_price" && selectedFiltersCount !== 0 ? (<span> ({selectedFiltersCount})</span>) : ""}
                </div>
              </div>
            );
          })}
        </div>

        <div className="filters-right ">
          <CustomFilter
            title={selectedFilter?.title}
            type={selectedFilter?.type}
            deviceType={deviceType}
            name={selectedFilter?.name}
            options={selectedFilter?.values}
            onChange={onChange}
          />
        </div>


        {/* <div className="menu-list">
          {process.browser && (
            <Collapse defaultActiveKey={filterListState && filterListState.length ? filterListState[0].title : 'Price'}>
              {renderSidebarContent()}
            </Collapse>
          )}
        </div>
        <div className="actions-btn row m-0"> */}
        {/* <div className="col pr-2">
              <button type="button" className="btn btn-primary rouded-button btn-block" onClick={handleApplyFilter}>
                Apply
              </button>
            </div> */}
        {/* <div className="col ">
            <button type="button" className="btn btn-outline-primary rouded-button  btn-block" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
 */}
        <div>

        </div>
      </div >
    </div >
  );
};

export default FilterContent;
