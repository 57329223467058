import React, { useState, useEffect } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import FilterContent from './FilterContent';
import * as WEBSITES from '../constants/website';
import { useRouter } from 'next/router';

const Filter = ({
  filterList: filters,
  applyFilters,
  cancelFilters,
  children,
  deviceType,
  hideFilters = [],
  isPopup,
  currentTab,
  asapProductsCount,
  scheduledProductsCount,
  isFilterPopupVisible,
  isFilterApplied = false,
  isDealsPage = false
}) => {
  const [clearId, setClearId] = useState(true);

  const scrollToFilter = () => {
    const header = document.getElementsByClassName('bento-header')[0];
    const deliveryTabs = document.getElementById("delivery-tabs-box")

    if (deliveryTabs?.getBoundingClientRect().top - 55 - 20 > header?.clientHeight) { // 55px is taken by category section, 20px is buffer
      if (deliveryTabs?.getBoundingClientRect().top - 55 - 20 - 37 > header?.clientHeight) return //39px is the label header height
    }

    const element = document.getElementsByClassName('filter-product-page')[0];
    const headerOffset = 150;
    const elementPosition = element?.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition
    });
  };

  const [filterList, setFilterList] = useState([...filters]);

  useEffect(() => {
    if (!isFilterPopupVisible) {
      const list = filterList.map(filter => {
        const tempFilter = filter;
        const { name = '', values = [], title = '' } = tempFilter;
        if (name === 'product_price') return tempFilter;

        // Ordering based on the array has checked items or not
        const hasCheckedItems = values.some(item => item.checked)
        if (hasCheckedItems) {
          const checked = values.filter(item => item.checked)
          const unchecked = values.filter(item => !item.checked)
          tempFilter.values = [...checked, ...unchecked]
        } else {
          let orderedList = [...values]
          if (name === "brands") {
            orderedList.sort((a, b) => a.value.localeCompare(b.value))
          } else if (name === "deviceTypes") {
            orderedList.sort((a, b) => parseInt(a.id) - parseInt(b.id))
          }
          else if (title === "Categories") {
            orderedList.sort((a, b) => a?.order - b?.order)
          }
          else {
            orderedList.sort((a, b) => parseInt(a.id.split("-")[1]) - parseInt(b.id.split("-")[1]))
          }
          tempFilter.values = [...orderedList]
        }
        return tempFilter;
      });
      setFilterList(list);
    }
  }, [isFilterPopupVisible]);

  useEffect(() => {
    setFilterList([...filters]);
  }, [filters]);

  useEffect(() => {
    if (isPopup) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('modal-open');
      body.classList.add('hide-acsb-icon');
      return () => {
        body.classList.remove('modal-open');
        body.classList.remove('hide-acsb-icon');
      };
    }
  }, []);

  const hidePopup = e => {
    if (e.target.classList.value.split(' ').includes('modal')) {
      cancelFilters();
    }
  };

  const clearFilters = () => {
    const tempFilters = filterList.map(item => {
      const tempFilter = { ...item };

      tempFilter.values = tempFilter.values.map((subItem, index) => {
        const optionValue = { ...subItem };

        if (optionValue.type === 'checkbox') {
          optionValue.checked = false;
        }

        if (optionValue.type === 'radio') {
          delete optionValue.intended;
          optionValue.checked = index === 0;
        }
        return optionValue;
      });
      return tempFilter;
    });

    applyFilters(tempFilters);
    scrollToFilter();
  };

  if (isPopup) {
    // mobile-bottom-sticky-desktop-center-popup
    return (
      // <CSSTransition appear in mountOnEnter unmountOnExit timeout={300} classNames="slideup-wrapper">
        <div
          className="modal  filter-popup d-block  animated-popup-wrapper"
          tabIndex="-1"
          onClick={e => hidePopup(e)}
          role="dialog"
        >
          <div className="modal-dialog  " role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-between align-items-center pb-2 no-border">
                <h6 className="modal-title text-center bold">
                  {process.env.NEXT_PUBLIC_APP == WEBSITES.GRASSDOOR ? 'Filter and Sort' : 'Filters'}
                </h6>
                <div>
                  <button type="button" className="btn " onClick={cancelFilters}>
                    <i className="icon-close" />
                  </button>
                </div>
              </div>
              <div className="modal-body py-0">
                {children || (
                  <>
                    {filterList?.length && (
                      <FilterContent
                        isDealsPage={isDealsPage}
                        clearId={clearId}
                        filterList={filterList}
                        applyFilters={applyFilters}
                        cancelFilters={cancelFilters}
                        deviceType={deviceType}
                        hideFilters={hideFilters}
                        scrollToFilter={scrollToFilter}
                        isFilterPopupVisible={isFilterPopupVisible}
                      />
                    )}
                  </>
                )}
              </div>

              <div className="modal-footer no-border">
                <div className="d-flex justify-content-between is-fullwidth">
                  <button disabled={!isFilterApplied} type="button" className="btn btn-outline min-width-btn" onClick={clearFilters}>
                    Clear Filters
                  </button>
                  <button type="button" className="btn btn-primary min-width-btn" onClick={cancelFilters}>
                    Show
                    {' '}
                    {currentTab === 'asap' ? asapProductsCount : scheduledProductsCount} Products
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      // </CSSTransition>
    );
  }
  if (filterList?.length) {
    return (
      <FilterContent
        isDealsPage={isDealsPage}
        // clearId={clearId}
        applyFilters={filters => applyFilters(filters)}
        clearFilters={clearFilters}
        cancelFilters={cancelFilters}
        deviceType={deviceType}
        hideFilters={hideFilters}
        filterList={filterList}
        scrollToFilter={scrollToFilter}
        isFilterPopupVisible={isFilterPopupVisible}
      />
    );
  }
  return null;
};

export default Filter;
